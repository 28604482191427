let prefix = {
  A: "on or after August 1983.",
  B: "on or after August 1984.",
  C: "on or after August 1985.",
  D: "on or after August 1986.",
  E: "on or after August 1987.",
  F: "on or after August 1988.",
  G: "on or after August 1989.",
  H: "on or after August 1990.",
  J: "on or after August 1991.",
  K: "on or after August 1992.",
  L: "on or after August 1993.",
  M: "on or after August 1994.",
  N: "on or after August 1995.",
  P: "on or after August 1996.",
  R: "on or after August 1997.",
  S: "on or after August 1998.",
  T: "on or after March 1999.",
  V: "on or after September 1999.",
  W: "on or after March 2000.",
  X: "on or after September 2000.",
  Y: "on or after Match 2001.",
};
let sufix = {
  A: "on or before 1963.",
  B: "on or after 1964.",
  C: "on or after 1965.",
  D: "on or after 1966.",
  E: "on or after Jan 1967 – Jul 1967.",
  F: "on or after Aug 1967 – Jul 1968.",
  G: "on or after Aug 1968 – Jul 1969.",
  H: "on or after Aug 1969 – Jul 1970.",
  J: "on or after Aug 1970 – Jul 1971.",
  K: "on or after Aug 1971 – Jul 1972.",
  L: "on or after Aug 1972 – Jul 1973.",
  M: "on or after Aug 1973 – Jul 1974.",
  N: "on or after Aug 1974 – Jul 1975.",
  P: "on or after Aug 1975 – Jul 1976.",
  R: "on or after Aug 1976 – Jul 1977.",
  S: "on or after Aug 1977 – Jul 1978.",
  T: "on or after Aug 1978 – Jul 1979.",
  V: "on or after Aug 1979 – Jul 1980.",
  W: "on or after Aug 1980 – Jul 1981.",
  X: "on or after Aug 1981 – Jul 1982.",
  Y: "on or after Aug 1982 – Jul 1983.",
};

export { prefix,sufix };
