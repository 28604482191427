import React, { useEffect, useLayoutEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setData } from "../../store/slices/cartSlice";
import { apiRequest } from "../../utils/api";
import { prefix } from "../../utils/prefixList";
import { numberSpacing, removeSpaces } from "../../utils/function";
import { calculateVat } from "../../utils/tax";
import { setFinanceData } from "../../store/slices/financeSlice";
import { buylineEnable, getData } from "../../utils/storage";
import { showFinanceOptions } from "../../utils/InterestCalculator";
import BuylineCalculator from "./BuylineCalculator";

function ViewBanner({ search, price, page, route, manufactured, myNumber }) {
  const { number } = useParams();
  const numberNoSpace = removeSpaces(number);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const cart = useSelector((s) => s.cart.data);
  const [commission, setCommission] = useState([]);
  const [isVat, setIsVat] = useState(0);
  const [pCommission, setPCommission] = useState(0);
  // const [adminPrice, setAdminPrice] = useState(0);
  const [pPrice, setPPrice] = useState(0);
  const [pVat, setPVat] = useState(0);
  const resBuyline = useSelector((s) => s.buyline.data.enable);
  const isBuylineEnable = resBuyline;
  const imgUrl = isBuylineEnable ? "/img/visa.png" : "/img/visa3.png";

  useEffect(() => {
    if (page == "Premium") {
      getPlate();
    }
  }, []);

  // if page is premium category

  async function getPlate() {
    try {
      let res = await apiRequest.get(`/premium/seller/search/single/${number}`);
      if (res.data.status === 200) {
        const { message } = res.data;
        setPCommission(parseFloat(message?.commission));
        setIsVat(parseFloat(message?.vat));
        setPPrice(parseFloat(message?.sell_price));
      } else {
        console.log(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  }

  let newVat = isVat
    ? parseFloat(parseFloat(price) * 0.2).toFixed(2)
    : ((price - pPrice) * 0.2).toFixed(2);

  // if page is premium category ends
  let dvlaPrice = parseFloat(price); //999
  let dvlaFee = 80;
  let bbb = (((dvlaPrice - dvlaFee) / 120) * 20).toFixed(2); //153.16
  let sellPrice = parseFloat(dvlaPrice - dvlaFee - bbb).toFixed(2); //765.83

  let singleCommissionObject = commission.filter(
    (c) => c.min_price <= dvlaPrice && c.max_price >= dvlaPrice
  )[0];

  let mainCommission = singleCommissionObject?.commission;

  let commissionPrice = (
    (parseFloat(sellPrice) * parseFloat(mainCommission)) /
    100
  ).toFixed(2);

  let newPrice = parseFloat(
    parseFloat(sellPrice) + parseFloat(commissionPrice)
  ).toFixed(2);
  let vat = parseFloat(0.2 * parseFloat(newPrice));

  let total = parseFloat(
    parseFloat(newPrice) + parseFloat(vat) + parseFloat(dvlaFee)
  ).toFixed(2);
  function addToCart() {
    dispatch(
      setData({
        category: route === "view-premium" ? search.category : route,
        fullNumber: number,
        price: page === "Premium" ? price : newPrice,
        vat: page === "Premium" ? parseFloat(newVat).toFixed(2) : vat,
        isPremium: route === "view-premium" ? true : false,
      })
    );
    localStorage.setItem(
      "cart-plate",
      JSON.stringify({
        category: route === "view-premium" ? search.category : route,
        fullNumber: number,
        price: page === "Premium" ? price : newPrice,
        vat: page === "Premium" ? parseFloat(newVat).toFixed(2) : vat,
        isPremium: route === "view-premium" ? true : false,
      })
    );
    localStorage.setItem("plate-info", manufactured);
    navigate("/checkout");
  }

  useEffect(() => {
    getCommissions();
  }, []);

  async function getCommissions() {
    try {
      let res = await apiRequest.get("/commission/all");
      if (res.data.status === 200) {
        setCommission(res.data.message);
      } else {
        console.log(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function financeCheckout() {
    dispatch(setFinanceData(true));
    addToCart();
  }

  const financeOptions = showFinanceOptions(
    page === "Premium"
      ? parseFloat(parseFloat(newVat) + parseFloat(price) + dvlaFee).toFixed(2)
      : total
  );

  return (
    <div>
      <div className="view-banner">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="view-form">
                <div className="view-form-head">
                  <h2>{numberSpacing(route, number)}</h2>
                  <p>
                    This registration is only suitable for vehicles manufactured{" "}
                    <span>{manufactured}</span>
                  </p>
                </div>
                <div className="view-form-body">
                  <ul>
                    <li>
                      <h2>Registration:</h2>
                      <span>
                        £
                        {page === "Premium"
                          ? parseFloat(price).toFixed(2)
                          : parseFloat(newPrice).toFixed(2)}
                      </span>
                    </li>
                    <li>
                      <h2>VAT:</h2>
                      <span>
                        £
                        {page === "Premium"
                          ? parseFloat(newVat).toFixed(2)
                          : parseFloat(vat).toFixed(2)}
                      </span>
                    </li>
                    <li>
                      <h2>Compulsory DVLA Fee</h2>
                      <span>£{dvlaFee}</span>
                    </li>
                    <li className="total-free">
                      <h2>Total</h2>
                      <span>
                        £
                        {page === "Premium"
                          ? parseFloat(
                              parseFloat(newVat) + parseFloat(price) + dvlaFee
                            ).toFixed(2)
                          : total}
                      </span>
                    </li>
                  </ul>
                  <div className="view-button-form">
                    {page === "Premium" ? (
                      <>
                        <button
                          onClick={() => navigate(`/reg-enquiry/${number}`)}
                          type="button"
                          className="btn btn-warning"
                        >
                          Make An Offer
                        </button>
                        <button
                          type="button"
                          onClick={addToCart}
                          className="btn btn-dark"
                        >
                          Buy Now
                        </button>
                      </>
                    ) : (
                      <button
                        onClick={addToCart}
                        type="button"
                        className="btn btn-dark"
                      >
                        Buy Now
                      </button>
                    )}
                  </div>
                  {isBuylineEnable &&
                    parseFloat(price || newPrice) +
                      80 +
                      calculateVat(price || newPrice) >
                      250 && (
                      <div className="view-button-form outline">
                        <button
                          onClick={financeCheckout}
                          type="button"
                          className="btn btn-dark"
                        >
                          buy with finance
                        </button>
                      </div>
                    )}
                  <div className="visa-img">
                    <img crossOrigin="anonymous" src={imgUrl} />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="audi-img">
                <img crossOrigin="anonymous" src="/img/car.png" />
                <h3>{numberSpacing(route, number)}</h3>
              </div>
            </div>
          </div>
        </div>
        {isBuylineEnable ? (
          <>
            <div className="container mt-5">
              <BuylineCalculator
                term={48}
                apr={19.9}
                theme={1}
                price={
                  page === "Premium"
                    ? parseFloat(
                        parseFloat(newVat) + parseFloat(price) + dvlaFee
                      ).toFixed(2)
                    : total
                }
              />
            </div>
          </>
        ) : null}
        {/* {isBuylineEnable &&
          parseFloat(price || newPrice) + 80 + calculateVat(price || newPrice) >
            250 && (
            <div className="interest-rates-section">
              <div className="container">
                <div className="interest-title">{financeOptions.message}</div>
                <div className="terms-rate-options">
                  {financeOptions?.termsArr?.map((t, i) => (
                    // <ul>
                    //   <li>
                    <p key={i}>{t}</p>
                    //   </li>
                    // </ul>
                  ))}
                  <p>
                    Finance is not available on private registrations less than
                    £250.
                  </p>
                </div>
              </div>
            </div>
          )} */}
      </div>
    </div>
  );
}

export default ViewBanner;
