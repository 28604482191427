import React, { useEffect } from "react";

const CLIENT_ID = "7fKuPtQQaY578tlgj6ED7X8uyVlIAdzS";
function BuylineCalculator({ price, term, apr,theme }) {
  useEffect(() => {
    if (!document.querySelector("#buyline-widget-script")) {
      const script = document.createElement("script");
      script.id = "buyline-widget-script"; // Unique ID to prevent duplicates
      script.src =
        "https://vapor-eu-west-2-storage-production-63932.s3.eu-west-2.amazonaws.com/build/js/widget.js?v=1.0.0";
      script.async = true;

      script.onload = () => {
        if (typeof window.buylineWidget === "function") {
          window.buylineWidget("#buyline-finance-calculator", {
            price,
            apr,
            term,
            theme,
            clientId: CLIENT_ID,
          });
        }
      };

      script.onerror = () => {
        console.error("Failed to load Buyline finance script.");
      };

      document.body.appendChild(script);
    }
  }, []);

  const updatePrice = (newPrice) => {
    const evt = new CustomEvent("buyline_update_widget", {
      bubbles: true,
      cancelable: false,
      detail: { amount: newPrice },
    });
    document.dispatchEvent(evt);
  };

  setTimeout(() => {
    updatePrice(price);
  }, 1000);

  return (
    <div>
      <div id="buyline-finance-calculator"></div>
      {/* <button onClick={() => updatePrice(300)}>Update Price to £300</button> */}
    </div>
  );
}

export default BuylineCalculator;
