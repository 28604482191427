import React, { useEffect, useLayoutEffect, useState } from "react";

import AdvancedSearch from "../home/components/AdvancedSearch";
import PremiumRegistrations from "../home/components/PremiumRegistrations";
import ViewNumber from "../home/components/ViewNumber";
import { apiRequest } from "../../utils/api";
import { useNavigate, useParams } from "react-router-dom";
import Seller from "../home/components/Seller";
import Loader from "../home/components/Loader";
import Toast, { errorToast } from "../../utils/Toast";
import { extractNumber } from "../../utils/function";
import { addData, getData } from "../../utils/storage";
import usePageTitleAndMeta from "../../hooks/usePageTitleAndMeta";

function MainSearch() {
  const [newStyles, setNewStyles] = useState([]);
  const [oldStyles, setOldStyles] = useState([]);
  const [getPrefixStyles, setGetPrefixStyles] = useState([]);
  const [oldAll, setOldAll] = useState([]);
  const [newAll, setNewAll] = useState([]);
  const [ppAll, setPpAll] = useState([]);
  const [preAll, setPreAll] = useState([]);
  const [premiums, setPremiums] = useState([]);
  const [allArray, setAllArray] = useState([]);
  const { keyword } = useParams();
  const [plate, setPlate] = useState(keyword.toLowerCase());
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);

  const [searchNumber, setSearchNumber] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    document.getElementById("submitBtn").click();
    addData("search-plate", keyword);

    localStorage.removeItem("plate-info");
  }, []);

  async function submit(e) {
    e.preventDefault();
    if (!plate) {
      setNewStyles([]);
      setOldStyles([]);
      setGetPrefixStyles([]);
      setPremiums([]);
      return errorToast("Please Enter Number");
    }
    setIsLoading(true);
    addData("search-plate", plate);
    try {
      await getNewStyles(plate);
      await getOldStyles(plate);
      await getAllPrefixStyles(plate);
      await getPremiums(plate);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }

    navigate("/search/" + getData("search-plate"));
  }

  async function getPremiums(plate) {
    let res = await apiRequest.get(`/premium/search/${plate}/1`);
    if (res.data.status === 200) {
      let result = res.data.message;

      let valid = result.filter((p) => p.sell_number_status === "APPROVED");

      let all = valid.map((r) => ({
        fullNumber: r.sell_number,
        price: r.admin_price,
        route: "view-premium",
      }));

      setPreAll(all);

      setPremiums(
        result
          .filter((r) => r.sell_number_status === "APPROVED")
          .map((r) => ({
            fullNumber: r.sell_number,
            price: r.admin_price
              ? parseFloat(r.admin_price)
              : parseFloat(r.sell_price),
          }))
      );
    } else {
      setNewStyles([]);
      console.log(res.data.message);
    }
  }
  async function getNewStyles(plate) {
    let res = await apiRequest.get(`/plate/new-style/search/${plate}/${page}`);
    if (res.data.status === 200) {
      let { message } = res.data;

      let value = message.map((p) => ({
        price: p.price,
        fullNumber: p.fullNumber?.replace(
          extractNumber(p.fullNumber),
          `${extractNumber(p.fullNumber)} `
        ),
      }));

      let valueSet = new Set(value);

      setNewStyles([...valueSet]);

      let all = res.data.message?.map((p) => ({
        price: p.price,
        fullNumber: p.fullNumber,
        route: "view",
      }));

      setNewAll(all);
    } else {
      setNewStyles([]);
      console.log(res.data.message);
    }
  }
  async function getOldStyles(plate) {
    let res = await apiRequest.get(`/old/date-less/search/${plate}/${page}`);
    if (res.data.status === 200) {
      let result = res.data.message;

      setOldStyles(
        result.map((r) => ({
          fullNumber: r.old_fullNumber?.replace(
            extractNumber(r.old_fullNumber),
            ` ${extractNumber(r.old_fullNumber)}`
          ),
          price: r.old_price,
        }))
      );

      let all = result.map((r) => ({
        fullNumber: r.old_fullNumber,
        price: r.old_price,
        route: "view-old",
      }));

      setOldAll(all);
    } else {
      setNewStyles([]);
      console.log(res.data.message);
    }
  }
  async function getAllPrefixStyles(plate) {
    let res = await apiRequest.get(
      `/prefix/prefix-style/search/${plate}/${page}`
    );
    if (res.data.status === 200) {
      let result = res.data.message;

      setGetPrefixStyles(
        result.map((r) => ({
          fullNumber: r.pp_fullNumber?.replace(
            extractNumber(r.pp_fullNumber),
            `${extractNumber(r.pp_fullNumber)} `
          ),
          price: r.pp_price,
        }))
      );

      let all = result.map((r) => ({
        fullNumber: r.pp_fullNumber,
        price: r.pp_price,
        route: "view-prefix",
      }));

      setPpAll(all);
    } else {
      setNewStyles([]);
      console.log(res.data.message);
    }
  }

  usePageTitleAndMeta("JDM Plates", "JDM Plates", "JDM Plates");

  useEffect(() => {
    localStorage.removeItem("uuid");
    localStorage.removeItem("cart-plate");
    localStorage.removeItem("plate-info");
    if (isLoading) document.title = "Searching.....";
    else document.title = "JDM Plates";
  }, [isLoading]);

  useLayoutEffect(() => {
    let allArr = [...newAll, ...oldAll, ...ppAll, ...preAll];
    setAllArray(allArr);
    setSearchNumber(
      allArr.filter(
        (r) =>
          r.fullNumber?.replace(/\s+/g, "").toLowerCase() ===
          plate?.replace(/\s+/g, "").toLowerCase()
      )
    );
  }, [newAll, ppAll, oldAll, preAll]);

  return (
    <div>
      {isLoading && <Loader />}
      <div className="home-banner">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="banner-serch-bar">
                <div className="banner-serch-bar-title">
                  <h2>We Make Buying Registrations Fast & Easy...</h2>
                </div>
                <form onSubmit={submit}>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      placeholder="Enter your initials, name, car or anything else..."
                      value={plate}
                      onChange={(e) => setPlate(e.target.value)}
                    />
                    <button
                      className="btn btn-dark"
                      id="submitBtn"
                      type="submit"
                    >
                      Search
                    </button>
                  </div>
                </form>
                {/* <h3>Enter your initials, name, car or anything else...</h3>
                <div className="rating-row">
                  <img crossOrigin="anonymous" src="/img/google.png" />
                  <h2>
                    Google Rating <strong>4.8</strong>
                  </h2>
                  <ul>
                    <li>
                      <span>
                        <i className="bi bi-star-fill"></i>
                      </span>
                    </li>
                    <li>
                      <span>
                        <i className="bi bi-star-fill"></i>
                      </span>
                    </li>
                    <li>
                      <span>
                        <i className="bi bi-star-fill"></i>
                      </span>
                    </li>
                    <li>
                      <span>
                        <i className="bi bi-star-fill"></i>
                      </span>
                    </li>
                    <li>
                      <span>
                        <i className="bi bi-star-fill"></i>
                      </span>
                    </li>
                  </ul>
                  <h6>See all our reviews</h6>
                </div> */}
              </div>
            </div>
            <div className="col-md-6">
              <div className="car-img">
                <img src="/img/number-car.png" />
              </div>
            </div>
          </div>
        </div>
      </div>
      {searchNumber[0] && <AdvancedSearch details={searchNumber[0]} />}
      {searchNumber[0] && <ViewNumber details={searchNumber[0]} />}
      {/* for new styles dark bg */}
      {newStyles.length > 0 ? (
        <>
          <div className="home-content-btns">
            <a href="#prefixStyle">Prefix</a>
            <a href="#premium">Premium</a>
            <a href="#dateless">Dateless</a>
          </div>
          <div className="dark-bgPremiumRegistrations">
            <PremiumRegistrations
              title={`NEW STYLE '${getData(
                "search-plate"
              )?.toUpperCase()}' REGISTRATIONS`}
              plates={newStyles}
              page={"new-plates"}
              plate={plate}
            />
          </div>
        </>
      ) : null}
      {/* for prefix */}
      {getPrefixStyles.length > 0 ? (
        <div className="dark-bgPremiumRegistrations" id="prefixStyle">
          <PremiumRegistrations
            title={`PREFIX STYLE '${getData(
              "search-plate"
            )?.toUpperCase()}' REGISTRATIONS`}
            plates={getPrefixStyles}
            page={"prefix"}
            plate={plate}
          />
        </div>
      ) : null}
      {/* premiums */}
      {premiums.length > 0 ? (
        <div className="dark-bgPremiumRegistrations" id="premium">
          <Seller
            title={`PREMIUM ALL REGISTRATIONS`}
            plate={plate}
            plates={premiums}
          />
        </div>
      ) : null}
      {/* for old plates */}
      {oldStyles?.length > 0 ? (
        <div id="dateless">
          <PremiumRegistrations
            title={`Dateless '${getData(
              "search-plate"
            )?.toUpperCase()}' REGISTRATIONS`}
            plates={oldStyles}
            page={"old"}
            plate={plate}
          />
          <div className="container"></div>
        </div>
      ) : null}
    </div>
  );
}

// function MainSearch() {
//   const { keyword } = useParams();
//   const [newStyles, setNewStyles] = useState([]);
//   const [plate, setPlate] = useState(keyword?.toUpperCase());
//   const [page, setPage] = useState(1);

//   const [searchNumber, setSearchNumber] = useState("");
//   const navigate = useNavigate();

//   async function submit(e) {
//     e.preventDefault();
//     try {
//       let res = await apiRequest.get(
//         `/plate/new-style/search/${plate}/${page}`
//       );
//       if (res.data.status === 200) {
//         setNewStyles(res.data.message);
//         setSearchNumber(
//           res.data?.message?.filter(
//             (p) => p?.fullNumber?.toLowerCase() === plate.toLowerCase()
//           )
//         );
//       } else {
//         setNewStyles([]);
//         console.log(res.data.message);
//       }
//     } catch (error) {
//       console.log(error);
//     }
//   }

//   useEffect(() => {
//     pageSearch();
//   }, []);
//   async function pageSearch() {
//     try {
//       let res = await apiRequest.get(
//         `/plate/new-style/search/${plate}/${page}`
//       );
//       if (res.data.status === 200) {
//         setNewStyles(res.data.message);
//         setSearchNumber(
//           res.data?.message?.filter(
//             (p) => p?.fullNumber?.toLowerCase() === plate.toLowerCase()
//           )
//         );
//       } else {
//         setNewStyles([]);
//         console.log(res.data.message);
//       }
//     } catch (error) {
//       console.log(error);
//     }
//   }

//   // async function changePage(e) {
//   //   setPage(page + 1);
//   //   await submit(e);
//   // }

//   console.log(searchNumber);

//   return (
//     <div>
//       <div className="home-banner">
//         <div className="container">
//           <div className="row">
//             <div className="col-md-12">
//               <div className="banner-serch-bar">
//                 <div className="form-group">
//                   <input
//                     type="text"
//                     className="form-control"
//                     id="exampleInputEmail1"
//                     aria-describedby="emailHelp"
//                     placeholder="EX: NI13KUY"
//                     value={plate}
//                     onChange={(e) => setPlate(e.target.value)}
//                   />
//                   <button
//                     className="btn btn-dark"
//                     type="button"
//                     onClick={submit}
//                   >
//                     Search
//                   </button>
//                 </div>
//                 <h3>Enter your initials, name, car or anything else...</h3>
//                 <div className="rating-row">
//                   <img crossOrigin="anonymous" src="/img/google.png" />
//                   <h2>
//                     Google Rating <strong>4.8</strong>
//                   </h2>
//                   <ul>
//                     <li>
//                       <span>
//                         <i className="bi bi-star-fill"></i>
//                       </span>
//                     </li>
//                     <li>
//                       <span>
//                         <i className="bi bi-star-fill"></i>
//                       </span>
//                     </li>
//                     <li>
//                       <span>
//                         <i className="bi bi-star-fill"></i>
//                       </span>
//                     </li>
//                     <li>
//                       <span>
//                         <i className="bi bi-star-fill"></i>
//                       </span>
//                     </li>
//                     <li>
//                       <span>
//                         <i className="bi bi-star-fill"></i>
//                       </span>
//                     </li>
//                   </ul>
//                   <h6>See all our reviews</h6>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//       {searchNumber[0] && <AdvancedSearch details={searchNumber[0]} />}
//       {searchNumber[0] && <ViewNumber details={searchNumber[0]} />}
//       {newStyles?.length > 0 ? (
//         <>
//           <PremiumRegistrations
//             title={`NEW STYLE '${plate.toUpperCase()}' REGISTRATIONS`}
//             plates={newStyles}
//           />
//           <div className="container">
//             <div className="row mb-5">
//               <div className="col-4"></div>
//               <div className="col-4 text-center">
//                 <button
//                   className="btn btn-dark"
//                   onClick={() => navigate(`/new-plates/${plate}/${page}`)}
//                 >
//                   View More
//                 </button>
//               </div>
//               <div className="col-4"></div>
//             </div>
//           </div>
//         </>
//       ) : null}
//       {/* for dark bg */}
//       {false && (
//         <div className="dark-bgPremiumRegistrations">
//           <PremiumRegistrations />
//         </div>
//       )}
//     </div>
//   );
// }

export default MainSearch;
